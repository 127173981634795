<template>
	<div class="code-container">
		<el-upload :show-file-list="false" ref="upload" accept=".xlsx,.XLSX,.xls,.XLS" action :http-request="upload" :on-change="handleChange" v-show="false" :before-upload="beforeAvatarUpload">
			<el-button size="small" type="primary">{{$t('common.uploading')}}</el-button>
		</el-upload>
		<el-upload :show-file-list="false" ref="upload2" accept=".xlsx,.XLSX,.xls,.XLS" action :http-request="httpUpload2" :on-change="handleChange2" v-show="false" :before-upload="beforeAvatarUpload2">
			<el-button size="small" type="primary">{{$t('common.uploading')}}</el-button>
		</el-upload>
		<div class="com">
			<!-- <div class="template-header">
				<div class="header-left"><span>资产参数列表</span></div>
			</div> -->
			<div class="template-main">
				<div class="tree-container">
					<div class="title"><span>{{$t('property.specification')}}</span>
						<span class="copySpan">
							<span v-if="tier==0?spacePermiss.isSmartAdmin:(tier == 1?spaceAction.addspacetemplate:action.addprojecttemplate)" @click="isCreateTemFloder" class="el-icon-folder-add" style="font-size: 20px;"></span>
						</span>
					</div>
					<div class="treeAdd" v-show="isAddStand">
						<div class="tree-add">
							<el-input v-focus="focusStateStand" placeholder="输入样表名称" @keyup.enter.native="newStandardEnter()" v-model="labelStandard"
							 v-show="isAddStand" @blur="onInputBlur">
								<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleAddStandClick()"></el-button>
							</el-input>
						</div>
					</div>
					<div class="treeAdd" v-show="isAddFloder">
						<div class="tree-add">
							<el-input v-focus="focusFloderInput" placeholder="输入文件夹名称" @keyup.enter.native="newFloder()" v-model="floderName"
							 v-show="isAddFloder" @blur="newFloder">
								<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleAddStandClick()"></el-button>
							</el-input>
						</div>
					</div>
					<div class="tree" :class="tier==1||2?'tree-height-space':'tree-height-project'">
						<el-collapse v-model="activeNames" @change="handelNodeFolder">
							<el-collapse-item v-for="(itemFolder,index) in data" :name="itemFolder.id" :key="index">
								<template slot="title">

									<div class="collapse-div">
										<div style="display: flex;justify-content: space-between;width: 345px;align-items: center;padding-left: 10px;box-sizing: border-box;">
											<span class="span1 spanw" :title="itemFolder.name" v-show="isUpdateSdandId!=itemFolder.id"><i style="margin-right: 5px;" class="el-icon-folder"></i>{{itemFolder.name}}</span>
											<span v-show="isUpdateSdandId!=itemFolder.id">
												<i style="margin-right: 10px;" class="el-icon-edit" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.updatespacetemplate:action.updateprojecttemplate)" @click.stop="showUpdateStand(itemFolder)"></i>
												<i class="el-icon-plus" v-if="tier==0?spacePermiss.isSmartAdmin:(tier == 1?spaceAction.addspacetemplate:action.addprojecttemplate)" style="margin-right:10px" v-show="!isAddStand" @click.stop="isAddStandard(itemFolder)"></i>
												<i class="el-icon-delete" @click.stop="DeleteStandard(itemFolder)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.deletespacetemplate:action.deleteprojecttemplate)"></i>
											</span>
											<el-input v-focus="focusStateUpdateStand" placeholder="输入文件夹名称" v-show="isUpdateSdandId==itemFolder.id"
											 @keyup.enter.native="updateStandSubmitEnter()" v-model="labelUpdateStand" @blur="onBlurUpdateStand(data,itemFolder)">
												<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleUpdateStandClick()"></el-button>
											</el-input>
										</div>
									</div>
								</template>
								<div v-for="item in itemFolder.standList" :key="item.id">
									<div class="tree-one tree-standard2 tree-standard" @click="handelNode(item)" :class="curID == item.id && 'tree-hover'">
										<span class="span1 spanw" :title="item.name" v-show="isUpdateSdandId!=item.id">{{item.name}}</span>
										<span class="btn-container">
											<i class="iconfont el-icon-download" @click="shareTo(item)" v-if="tier==0?true:(tier==1?spaceAction.sharespacetemplate:action.shareprojecttemplate)" v-show="isUpdateSdandId!=item.id"></i>
											<i class="el-icon-edit" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.updatespacetemplate:action.updateprojecttemplate)" v-show="isUpdateSdandId!=item.id" @click="showUpdateStand(item)"></i>
											<i class="el-icon-delete" @click="DeleteStandard(item)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.deletespacetemplate:action.deleteprojecttemplate)" v-show="isUpdateSdandId!=item.id"></i>
										</span>
										<el-input v-focus="focusStateUpdateStand" placeholder="输入属性分类名称" v-show="isUpdateSdandId==item.id"
										 @keyup.enter.native="updateStandSubmitEnter()" v-model="labelUpdateStand" @blur="onBlurUpdateStand(data,item)">
											<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleUpdateStandClick()"></el-button>
										</el-input>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
				<div class="tree-container">
					<div class="title title2"><span>{{$t('property.classification')}}</span>
						<span class="copySpan" v-show="!isAdd&&curID != 0">
							<i class="el-icon-sort" style="margin-right:10px" @click="sort(centerDate,1,positive1)"></i>
							<i class="el-icon-circle-plus-outline" style="margin-right:10px" @click="isAddParShow()" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.addspacetemplate:action.addprojecttemplate)"></i>
							<i class="el-icon-upload2" style="margin-right:10px" @click.stop="upload2()" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.addspacetemplate:action.addprojecttemplate)"></i>
						</span>
					</div>
					<div class="treeAdd" v-show="isAdd">
						<div class="tree-add" v-show="isAdd">
							<el-input v-focus="focusStatePar" placeholder="输入属性分类名称" @keyup.enter.native="newTreeNodeEnter" v-model="label" @blur="onInputBlurPar(centerDate)">
								<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleAddPerClick()"></el-button>
							</el-input>
						</div>
					</div>
					<div class="tree" :class="tier==1||2?'tree-height-space2':'tree-height-project'">
						<div v-for="item in centerDate" :key="item.id">
							<div class="tree-one tree-standard tree-one-item" @click="handelSecondNode(item)" :class="hoverID == item.id && 'tree-hover'">
								<span class="span1" :title="item.name" v-show="isUpdateParId!=item.id">{{item.name}}</span>
								<span class="span-icon-box">
									<i class="el-icon-edit" @click="updatePar(item)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.updatespacetemplate:action.updateprojecttemplate)" v-show="isUpdateParId!=item.id"></i>
									<i style="font-size: 18px;" class="iconfont icon-a-huowuduanyundandaoru1" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.updatespacetemplate:action.updateprojecttemplate)" @click="moveClass(item)"></i>
									<i class="el-icon-delete" @click="deletePar(item)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.deletespacetemplate:action.deleteprojecttemplate)" v-show="isUpdateParId!=item.id"></i>
								</span>
								<el-input v-focus="focusStateUpdatePar" placeholder="输入属性分类名称" v-show="isUpdateParId==item.id"
								 @keyup.enter.native="updateParSubmitEnter()" v-model="labelUpdatePar" @blur="onBlurUpdatePar(centerDate,item)">
									<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleUpdatePerClick()"></el-button>
								</el-input>
							</div>
						</div>
					</div>
				</div>
				<div class="tree-container">
					<div class="title title2"><span>{{$t('property.attribute')}}</span>
						<span class="copySpan">
							<i class="el-icon-sort" style="margin-right:10px" @click="sort(parameterList,2,positive2)"></i>
							<i class="el-icon-circle-plus-outline" style="margin-right:10px" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.addspacetemplate:action.addprojecttemplate)" v-show="!isAddPar&&addParInfoDivShow" @click="showAddPar()"></i>
							<i class="el-icon-upload2" style="margin-right:10px" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.addspacetemplate:action.addprojecttemplate)" v-show="!isAddPar&&addParInfoDivShow" @click.stop="upload1()"></i>
						</span>
					</div>
					<div class="treeAdd" v-show="isAddPar">
						<div class="tree-add" v-show="isAddPar">
							<el-input v-focus="focusStateParInfo" placeholder="s-例" @keyup.enter.native="addParInfoEnter()" v-model="labelPar" v-show="isAddPar" @blur="onInputBlurParInfo">
							<el-button slot="append" icon="el-icon-circle-close" @mousedown.native="cancleAddParInfoClick()"></el-button>
							</el-input>
						</div>
					</div>
					<div class="tree" :class="tier==1||2?'tree-height-space2':'tree-height-project'">
						<div v-for="item in parameterList" :key="item.id">
							<div class="tree-one tree-standard tree-one-item" @click="hoverPerInfoId = item.id" :class="hoverPerInfoId == item.id && 'tree-hover'">
								<span class="span1" :title="item.name" v-show="isUpdateParInfoId!=item.id">{{item.name}}</span>
								<span class="params-list-btn">
									<i class="el-icon-edit" @click="showUpdatePer(item)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.updatespacetemplate:action.updateprojecttemplate)" v-show="isUpdateParInfoId!=item.id"></i>
									<i class="el-icon-delete" @click="deleteParInfo(parameterList,item)" v-if="tier==0?spacePermiss.isSmartAdmin:(tier==1?spaceAction.deletespacetemplate:action.deleteprojecttemplate)" v-show="isUpdateParInfoId!=item.id"></i>
								</span>
								<el-input v-focus="focusStateParUpdateInfo" placeholder="输入文本" @keyup.enter.native="updateParInfoEnter()" v-model="labelUpdateParInfo" v-show="isUpdateParInfoId==item.id" @blur="onUpdateBlurParInfo(parameterList,item)">
								<el-button slot="append" class="elInput-circle-close" icon="el-icon-circle-close" @mousedown.native="cancleUpdateParInfoClick()"></el-button>
								</el-input>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-dialog :title="tier==1?'复制公共样表':'复制企业样表'" :visible.sync="copyStandardToThisVisible" :close-on-click-modal="false">
				<el-form :model="ruleFormCopyToThis" :rules="rulesCopyToThis" ref="ruleFormCopyToThis" label-width="110px" class="demo-ruleForm">
					<el-form-item label="选择样表" prop="space">
						<el-select v-model="ruleFormCopyToThis.space" placeholder="请选择">
							<el-option v-for="item in spaceStandardList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item  label="样表名称" prop="standardName">
						<el-input v-model="ruleFormCopyToThis.standardName"></el-input>
					</el-form-item>
				</el-form>
				<p style="text-align: right;">
					<el-button type="primary" icon="el-icon-success" @click="copyStandardTo('ruleFormCopyToThis')">确定</el-button>
				</p>
			</el-dialog>
			<el-dialog :visible.sync="copyVisible" :close-on-click-modal="false">
				<el-form :model="ruleFormCopy" :rules="rulesCopy" ref="ruleFormCopy" label-width="130px" class="demo-ruleForm">
					<el-form-item :label="$t('property.sampleName')" prop="name">
						<el-input v-model="ruleFormCopy.name"></el-input>
					</el-form-item>
					<el-form-item :label="$t('property.copyLocation')"  style="text-align: left;">

						<el-radio-group @change="changeTarget" v-model="radio">
              <el-radio v-if="isToSelecet.common"  v-model="radio" :label="0">{{$t('property.publicSpace')}}</el-radio>
					    <el-radio v-if="isToSelecet.space"  v-model="radio" :label="1">{{$t('property.enterprise')}}</el-radio>
					    <el-radio v-if="isToSelecet.project"  v-model="radio" :label="2">{{$t('property.property')}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<div class="inline-box">
						<el-form-item v-if="radio==1||radio==2" :label="$t('property.targetEnterprise')" prop="spaceId">
							<el-select :disabled="isDisabled" v-model="ruleFormCopy.spaceId" :placeholder="$t('common.pleaseChoose')+$t('property.targetEnterprise')" @change="toSpceChange">
								<el-option v-for="item in toSpaceList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="inline-box">
						<el-form-item v-if="radio==2" :label="$t('property.targetItem')" prop="projectId">
							<el-select v-model="ruleFormCopy.projectId" :placeholder="$t('common.pleaseChoose')+$t('property.targetItem')"  @change="toProjectChange">
								<el-option v-for="item in toProjectList" :key="item.id" :label="item.projectName" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="inline-box">
						<el-form-item :label="$t('property.folder')" prop="floderId">
							<el-select v-model="ruleFormCopy.floderId" :placeholder="$t('common.pleaseChoose')+$t('property.folder')">
								<el-option v-for="item in toFloderList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form>
				<p style="text-align: right;">
					<el-button type="primary" icon="el-icon-success" @click="copyToTarget('ruleFormCopy')">{{$t('common.confirmation')}}</el-button>
				</p>
			</el-dialog>
			<el-dialog title="移动属性分类列表" :visible.sync="moveClassDia" :close-on-click-modal="false">
				<el-form :model="moveTarget" ref="moveClassForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="目标文件夹" prop="folderId" :rules="[
				      { required: true, message: '请选择目标文件夹'}]">
						<el-select v-model="moveTarget.folderId"  placeholder="请选择" required @change="moveSelectFolder">
							<el-option v-for="(item,index) in data" :key="index" :label="item.name" :value="item.id" >
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="标准" prop="moveTargetId" :rules="[
				      { required: true, message: '请选择标准'}
				    ]">
						<el-select v-model="moveTarget.moveTargetId" placeholder="请选择" required>
							<el-option v-for="(item,index) in targetFolderList" :key="index" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitMoveClassForm('moveClassForm')">提交</el-button>
					</el-form-item>
				</el-form>

			</el-dialog>
		</div>
	</div>
</template>
<script>
import {
  mapState
} from 'vuex'
import {
  share
} from '@/mixins/share'
export default {
  // props: {
  // 	space: String,
  // },
  mixins: [share],
  data () {
    return {
      positive1: false,
      positive2: false,
      ruleFormCopyToThis: {
        space: '',
        standardName: ''
      },
      rulesCopyToThis: {
        standardName: {
          required: true,
          message: '请填写样表名称',
          trigger: 'blur'
        },
        space: {
          required: true,
          message: '请选择企业样表',
          trigger: 'change'
        }
      },
      curUserID: localStorage.getItem('USER_ID'),
      hoverPerInfoId: 0,
      spaceStandardList: [],
      searchValue: '',
      data: [],
      centerDate: [],
      defaultProps: {
        children: 'parameterList',
        label: 'label'
      },
      copyStandardToThisVisible: false,
      cancleAddStand: false,
      cancleAddPer: false,
      cancleUpdatePer: false,
      cancleUpdateStand: false,
      cancleAddParInfo: false,
      cancleUpdateParInfo: false,
      standardProject: [],
      isUpdateParId: 0,
      isUpdateSdandId: 0,
      isUpdateParInfoId: 0,
      addParInfoDivShow: false,
      curID: 0,
      selectStandardId: 0,
      hoverID: 0,
      isAdd: false,
      isAddStand: false,
      focusStateStand: false,
      focusStatePar: false,
      focusStateParInfo: false,
      focusStateUpdatePar: false,
      focusStateUpdateStand: false,
      focusStateParUpdateInfo: false,
      isAddPar: false,
      label: '',
      labelPar: '',
      labelUpdatePar: '',
      labelStandard: '',
      labelUpdateParmodel: '',
      labelUpdateParInfo: '',
      labelUpdateStand: '',
      zwNum: 0,
      parameterList: [],
      tier: 0,
      isAddFloder: false,
      focusFloderInput: false,
      floderName: '',
      selectFolderId: 0,
      floderIndex: -1,
      activeNames: [''],
      moveClassDia: false,
      moveItem: {},
      targetFolderList: [],
      moveTarget: {
        folderId: '',
        moveTargetId: ''
      },
      currAttributeNode: {}// 第一列中，当前选中的属性节点
    }
  },
  directives: {
    focus: {
      update: function (el, {
        value
      }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    }),
    ...mapState('spaceAction', {
      spaceAction: 'spaceAction'
    }),
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })

  },
  mounted () {
    // this.init();
    this.judgmentLevel()
    this.getStandard()
    if (13 % 3 == 0) {
      this.zwNum = 0
    } else {
      this.zwNum = 3 - (13 % 3)
    }
  },
  methods: {
    submitMoveClassForm (formName) {
      console.log(this.moveTarget.moveTargetId, this.moveItem.id)
      // console.log(this.mo)
      console.log(this.targetFolderList)
      // return
      this.$refs[formName].validate((valid) => {
        this.$axios.get(`/api/standard/moveparameter?ParameterId=${this.moveItem.id}&StandardId=${this.moveTarget.moveTargetId}`).then(res => {
          console.log(res)
          this.moveClassDia = false
          this.targetFolderList = []
          this.moveItem = {}
          this.parameterList = []
          this.hoverID = 0
          this.hoverPerInfoId = 0
          // this.sort(this.centerDate, 1, this.positive1)
          this.centerDate.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
          const index = this.centerDate.findIndex(item => item.id == this.moveItem.id)
          this.centerDate.splice(index, 1)
          this.$message({
            type: 'success',
            message: '操作成功'
          })

          this.getStandard()
        })
      })
    },
    moveClass (item) {
      this.moveClassDia = true
      this.moveItem = item
      setTimeout(() => {
        this.$refs.moveClassForm.resetFields()
      }, 1)
      console.log(this.moveItem)
    },
    judgmentLevel () {
      let routeName = this.$route.name
      if (routeName == 'commondataspecification') {
        this.tier = 0
      } else if (routeName == 'companydataspecification') {
        this.tier = 1
      } else {
        this.tier = 2
      }
      console.log(this.tier)
    },
    getStandard () {
      this.$axios.get(`/api/Standard/GetStandardList?Type=${this.tier}`).then(res => {
        console.log(res)
        res.forEach(item => {
          item.isShow = false
          // this.targetFolderList.push(...item.standList)
        })
        this.data = res
      })
    },
    // 点击文件夹的时候
    handelNodeFolder (val) {
      this.parameterList = []
      this.centerDate = []
      this.hoverID = 0
      this.hoverPerInfoId = 0
      this.curID = 0
      this.selectFolderId = val[val.length - 1]
    },
    // expand second node  点击的是样表行
    handelNode (item) {
      this.currAttributeNode = item
      this.floderIndex = -1
      this.hoverPerInfoId = 0
      this.centerDate = item.parameterList
      // this.sort(this.centerDate, 1, this.positive1)
      this.centerDate.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
      var _this = this
      _this.parameterList = []
      _this.addParInfoDivShow = false
      _this.isAddPar = false
      item.parameterList.forEach(e => {
        _this.parameterList = _this.parameterList.concat(e.parameterInfoList)
      })
      // this.sort(this.parameterList, 2, this.positive2)
      this.parameterList.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
      // click
      if (this.curID == item.id) {
        this.curID = this.curID ? 0 : item.id
      } else {
        this.curID = item.id
      }
      this.selectStandardId = item.id
      this.hoverID = 0
      this.isAdd = false
    },
    // 点击的是分类行
    handelSecondNode (item) {
      console.log(item)
      this.parameterList = item.parameterInfoList
      // this.sort(this.parameterList, 2, this.positive2)
      this.parameterList.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
      this.addParInfoDivShow = true
      this.hoverID = item.id
      this.hoverPerInfoId = 0
    },
    sort (arr, statu, positive) {
      if (positive) {
        arr.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
      } else {
        arr.sort((a, b) => a['name'] < b['name'] ? 1 : -1)
      }
      if (statu == 1) {
        this.positive1 = !positive
      } else {
        this.positive2 = !positive
      }
    },
    updatePar (son) {
      var _this = this
      this.isUpdateParId = son.id
      this.labelUpdatePar = son.name
      setTimeout(() => {
        _this.focusStateUpdatePar = true
      }, 1)
    },
    showUpdateStand (item) {
      var _this = this
      this.isUpdateSdandId = item.id
      this.labelUpdateStand = item.name
      setTimeout(() => {
        _this.focusStateUpdateStand = true
      }, 1)
    },
    // 控制样表输入框的焦点
    isAddStandard (item) {
      // if(!this.selectFolderId){
      // 	this.$message({
      // 		type: "warning",
      // 		message:'请先选择文件夹',
      // 	})
      // 	return
      // }
      this.selectFolderId = item.id
      var _this = this
      this.isAddStand = true
      setTimeout(() => {
        this.focusStateStand = true
      }, 1)
    },
    isAddParShow () {
      var _this = this
      this.isAdd = true
      setTimeout(() => {
        _this.focusStatePar = true
      }, 1)
    },
    DeleteStandard (item) {
      let id = 0
      let text = ''
      if (item.parentId == 0) {
        text = '此操作将删除该文件夹, 是否继续?'
      } else {
        text = '此操作将删除该样表, 是否继续?'
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Standard/DeleteStandardById?Id=' + item.id).then(res => {
          if (item.id == 0) {
            // var index = this.data.findIndex(n => n.id == item.id)
            // this.data.splice(index, 1);
            this.getStandard()
          } else {
            // var index = this.data.findIndex(n => n.id == this.selectFolderId)
            // console.log(index)
            // var index2 = this.data[index].standList.findIndex(n => n.id == item.id)

            // this.data[index].standList.splice(index, 1);
            this.getStandard()
          }
          this.$message({
            type: 'success',
            message: res
          })
          // 如果选中的行是删除的行，移除选中的
          if (item.id == this.selectStandardId) {
            this.hoverID = 0
            this.curID = 0
            this.centerDate = []
            this.parameterList = []
          }
        })
      })
    },
    // 显示参数实例的输入框
    showAddPar () {
      var _this = this
      this.isAddPar = true
      setTimeout(() => {
        _this.focusStateParInfo = true
      }, 1)
    },
    showUpdatePer (item) {
      var _this = this
      this.isUpdateParInfoId = item.id
      this.labelUpdateParInfo = item.name
      setTimeout(() => {
        _this.focusStateParUpdateInfo = true
      }, 1)
    },
    addParInfoEnter () {
      this.isAddPar = false
      this.focusStateParInfo = false
    },
    // 输入参数实例后的添加
    addParInfo () {
      var _this = this
      let label = this.labelPar
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入属性名称'
        })
        this.isAddPar = false
        this.focusStateParInfo = false
        return
      }
      if (this.parameterList.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该属性名称已存在'
        })
        this.isAddPar = false
        this.focusStateParInfo = false
        return
      } else {
        this.$axios.get('/api/Standard/AddParameterInfo?ParameterInfoName=' + label + '&ParameterId=' + this.hoverID).then(
          res => {
            var parInfo = {
              id: res.id,
              name: label,
              parameterId: this.hoverID,
              creatorName: localStorage.nickName,
              creationTimeStr: this.getFullTime()
            }
            this.parameterList.unshift(parInfo)
            this.$message({
              type: 'success',
              message: '添加成功'
            })
          })
      }
      this.labelPar = ''
      this.isAddPar = false
      this.focusStateParInfo = false
    },
    updateParInfoEnter () {
      this.isUpdateParInfoId = 0
      this.focusStateParUpdateInfo = false
    },
    // 编辑输入参数实例后的添加
    updateParInfo (parInfoList, item) {
      var label = this.labelUpdateParInfo
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入属性名称'
        })
        this.isUpdateParInfoId = 0
        this.focusStateParUpdateInfo = false
        return
      }
      if (item.name == label) {
        this.$message({
          type: 'warning',
          message: '属性名称未更改'
        })
        this.isUpdateParInfoId = 0
        this.focusStateParUpdateInfo = false
        return
      }
      if (parInfoList.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该属性名称已存在'
        })
        this.isUpdateParInfoId = 0
        this.focusStateParUpdateInfo = false
        return
      } else {
        this.$axios.get('/api/Standard/UpdateParameterInfo?ParameterInfoName=' + label + '&ParameterInfoId=' + item.id).then(
          res => {
            item.name = label
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          })
      }
      this.labelUpdateParInfo = ''
      this.isUpdateParInfoId = 0
      this.focusStateParUpdateInfo = false
    },
    updateParSubmitEnter () {
      this.isUpdateParId = 0
      this.focusStateUpdatePar = false
    },
    updateStandSubmitEnter () {
      this.isUpdateSdandId = 0
      this.focusStateUpdateStand = false
    },
    // 修改分类
    updateParSubmit (parList, item) {
      var label = this.labelUpdatePar
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入分类名称'
        })
        this.isUpdateParId = 0
        this.focusStateUpdatePar = false
        return
      }
      if (item.name == label) {
        this.$message({
          type: 'warning',
          message: '分类名称未更改'
        })
        this.isUpdateParId = 0
        this.focusStateUpdatePar = false
        return
      }
      if (parList.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该分类名称已存在'
        })
        this.isUpdateParId = 0
        this.focusStateUpdatePar = false
        return
      } else {
        this.$axios.get('/api/Standard/UpdateParameter?ParameterName=' + label + '&ParameterId=' + item.id).then(res => {
          item.name = label
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        })
      }
      this.labelUpdatePar = ''
      this.isUpdateParId = 0
      this.focusStateUpdatePar = false
    },
    updateStandSubmit (List, item) {
      var label = this.labelUpdateStand
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入样表名称'
        })
        this.isUpdateSdandId = 0
        this.focusStateUpdateStand = false
        return
      }
      if (item.name == label) {
        this.$message({
          type: 'warning',
          message: '样表名称未更改'
        })
        this.isUpdateSdandId = 0
        this.focusStateUpdateStand = false
        return
      }
      if (List.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该样表名称已存在'
        })
        this.isUpdateSdandId = 0
        this.focusStateUpdateStand = false
        return
      } else {
        var stand = {}
        stand.Id = item.id
        stand.Name = label
        stand.Type = item.type
        stand.SpaceIdOrProjectId = item.spaceIdOrProjectId
        this.$axios.post('/api/Standard/UpdateStandard', stand).then(res => {
          item.name = label
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        })
      }
      this.labelUpdateStand = ''
      this.isUpdateSdandId = 0
      this.focusStateUpdateStand = false
    },

    // 删除参数
    deleteParInfo (parameterList, item) {
      var _this = this
      this.$confirm('此操作将删除该属性实例, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Standard/DeleteParameterInfo?ParameterInfoId=' + item.id).then(res => {
          var index = this.data.findIndex(n => n.id == _this.selectStandardId)
          var indexPar = this.data[index].parameterList.findIndex(n => n.id == item.parameterId)
          var indexParInfo = this.data[index].parameterList[indexPar].parameterInfoList.findIndex(n => n.id == item.id)
          this.data[index].parameterList[indexPar].parameterInfoList.splice(indexParInfo, 1)
          if (_this.hoverID == 0) {
            _this.handelNode(_this.data[index])
          }
          this.$message({
            type: 'success',
            message: res
          })
        })
      })
    },
    copyStandardTo (ruleFormCopyToThis) {
      this.$refs[ruleFormCopyToThis].validate(valid => {
        let StandardId = this.ruleFormCopyToThis.space
        let Type = this.tier
        let SpaceIdOrProjectId = 0
        let StandardName = this.ruleFormCopyToThis.standardName
        if (this.tier == 1) {
          SpaceIdOrProjectId = localStorage.getItem('eleSpaceId')
        } else {
          SpaceIdOrProjectId = localStorage.getItem('eleProjectId')
        }
        if (valid) {
          this.$axios.get(`/api/Standard/CopyStandard?StandardId=` + StandardId + `&Type=${Type}` +
								`&SpaceIdOrProjectId=` + SpaceIdOrProjectId + `&StandardName=` + StandardName +
								`&ParentId=${this.selectFolderId}`)
            .then(res => {
              this.$message({
                type: 'success',
                message: '复制成功'
              })
              this.copyStandardToThisVisible = false
              this.getStandard()
            })
        }
      })
    },
    copyStandardToThis () {
      if (!this.selectFolderId) {
        this.$message({
          type: 'warning',
          message: '请先选择目标文件夹'
        })
        return
      }
      // 样表样表
      this.copyStandardToThisVisible = true
      this.ruleFormCopyToThis.space = ''
      this.ruleFormCopyToThis.standardName = ''
      // let
      this.$axios.get(`/api/Standard/GetStandardList?Type=${this.tier - 1}`).then(res => {
        console.log(res)
        for (let item of res) {
          this.spaceStandardList.push(...item.standList)
        }
        // this.spaceStandardList = res
      })
      if (this.tier == 2) {
        this.$axios.get(`/api/Standard/GetStandardList?Type=${this.tier - 2}`).then(res => {
          for (let item of res) {
            this.spaceStandardList.push(...item.standList)
          }
        })
      }
    },
    deletePar (item) {
      var _this = this
      this.$confirm('此操作将删除该属性分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/Standard/DeleteParameter?ParameterId=' + item.id).then(res => {
          var index = this.data.findIndex(n => n.id == _this.selectStandardId)
          var indexPar = this.data[index].parameterList.findIndex(n => n.id == item.id)
          this.data[index].parameterList.splice(indexPar, 1)
          this.$message({
            type: 'success',
            message: res
          })
          // 如果选中的分类行是删除的行，移除选中的分类id
          if (item.id == this.hoverID) {
            this.hoverID = 0
            this.addParInfoDivShow = false
          }
          this.parameterList = []
          this.data[index].parameterList.forEach(e => {
            _this.parameterList = _this.parameterList.concat(e.parameterInfoList)
          })
        })
      })
    },
    newStandardEnter () {
      this.isAddStand = false
      this.focusStateStand = false
    },
    newStandard () {
      let label = this.labelStandard
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入样表名称'
        })
        this.isAddStand = false
        this.focusStateStand = false
        return
      }
      if (this.data.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该样表名称已存在'
        })
        this.isAddStand = false
        this.focusStateStand = false
      } else {
        var sta = {}
        sta.Name = label
        console.log(this.tier)
        if (this.tier == 1) {
          sta.Type = 1
          sta.SpaceIdOrProjectId = localStorage.eleSpaceId
        } else if (this.tier == 0) {
          sta.Type = 0
          sta.SpaceIdOrProjectId = 0
        } else {
          sta.Type = 2
          sta.SpaceIdOrProjectId = localStorage.eleProjectId
        }
        sta.parentId = this.selectFolderId
        console.log(sta)
        this.$axios.post('/api/Standard/AddStandard', sta).then(res => {
          let index = this.data.findIndex(item => {
            return item.id == this.selectFolderId
          })
          let data = this.data[index]
          data.standList.unshift({
            id: res.id,
            name: label,
            parameterList: [],
            spaceIdOrProjectId: localStorage.eleProjectId,
            type: 2,
            creationTimeStr: this.getFullTime()
          })
          this.$set(this.data, index, data)
          // console.log(this.data[index])
          // this.data[index].standList.unshift({
          // 	id: res.id,
          // 	name: label,
          // 	parameterList: [],
          // 	spaceIdOrProjectId: localStorage.eleProjectId,
          // 	type: 2,
          // 	creationTimeStr: this.getFullTime()
          // })
          this.$message({
            type: 'success',
            message: '添加成功'
          })
        })
        this.labelStandard = ''
        this.isAddStand = false
        this.focusStateStand = false
      }
    },

    newTreeNodeEnter () {
      this.isAdd = false
      this.focusStatePar = false
    },
    // create a new tree
    newTreeNode (parameterList) {
      let label = this.label
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入分类名称'
        })
        this.isAdd = false
        this.focusStatePar = false
        return
      }
      if (parameterList.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该分类名称已存在'
        })
        this.isAdd = false
        this.focusStatePar = false
        return
      } else {
        this.$axios.get('/api/Standard/AddParameter?ParameterName=' + label + '&StandardId=' + this.curID).then(res => {
          parameterList.unshift({
            id: res.id,
            name: label,
            parameterInfoList: [],
            creationTimeStr: this.getFullTime()
          })

          this.$message({
            type: 'success',
            message: '添加成功'
          })
        })
      }
      this.label = ''
      this.isAdd = false
      this.focusStatePar = false
    },
    cancleAddStandClick () {
      this.cancleAddStand = true
    },
    cancleAddPerClick () {
      this.cancleAddPer = true
    },
    cancleUpdatePerClick () {
      this.cancleUpdatePer = true
    },
    cancleAddParInfoClick () {
      this.cancleAddParInfo = true
    },
    cancleUpdateParInfoClick () {
      this.cancleUpdateParInfo = true
    },
    cancleUpdateStandClick () {
      this.cancleUpdateStand = true
    },
    onInputBlur () {
      if (this.cancleAddStand) {
        this.labelStandard = ''
        this.isAddStand = false
        this.focusStateStand = false
        this.cancleAddStand = false
      } else {
        this.newStandard()
      }
    },
    onInputBlurPar (parameterList) {
      if (this.cancleAddPer) {
        this.label = ''
        this.isAdd = false
        this.focusStatePar = false
        this.cancleAddPer = false
      } else {
        this.newTreeNode(parameterList)
      }
    },
    onInputBlurParInfo () {
      if (this.cancleAddParInfo) {
        this.labelPar = ''
        this.isAddPar = false
        this.focusStateParInfo = false
        this.cancleAddParInfo = false
      } else {
        this.addParInfo()
      }
    },
    onUpdateBlurParInfo (parameterList, item) {
      if (this.cancleUpdateParInfo) {
        this.labelUpdateParInfo = ''
        this.isUpdateParInfoId = 0
        this.focusStateParUpdateInfo = false
        this.cancleUpdateParInfo = false
      } else {
        this.updateParInfo(parameterList, item)
      }
    },
    onBlurUpdatePar (item, son) {
      if (this.cancleUpdatePer) {
        this.labelUpdatePar = ''
        this.isUpdateParId = 0
        this.focusStateUpdatePar = false
      } else {
        this.updateParSubmit(item, son)
      }
    },
    onBlurUpdateStand (List, item) {
      if (this.cancleUpdateStand) {
        this.labelUpdateStand = ''
        this.isUpdateSdandId = 0
        this.focusStateUpdateStand = false
      } else {
        this.updateStandSubmit(List, item)
      }
    },
    getFullTime () {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
    },
    isCreateTemFloder () {
      this.isAddFloder = true
      setTimeout(() => {
        this.focusFloderInput = true
      }, 1)
    },
    newFloder () {
      let label = this.floderName
      if (label == '') {
        this.$message({
          type: 'warning',
          message: '未输入文件夹名称'
        })
        this.isAddFloder = false
        this.focusFloderInput = false
        return
      }
      if (this.data.findIndex(n => n.name == label) > -1) {
        this.$message({
          type: 'warning',
          message: '该文件夹已存在'
        })
        this.isAddFloder = false
        this.focusFloderInput = false
      } else {
        var sta = {}
        sta.Name = label
        console.log(this.tier)
        if (this.tier == 1) {
          sta.Type = 1
          sta.SpaceIdOrProjectId = localStorage.eleSpaceId
        } else if (this.tier == 0) {
          sta.Type = 0
          sta.SpaceIdOrProjectId = 0
        } else {
          sta.Type = 2
          sta.SpaceIdOrProjectId = localStorage.eleProjectId
        }
        sta.parentId = 0
        console.log(sta)
        this.$axios.post('/api/Standard/AddStandard', sta).then(res => {
          this.data.unshift({
            id: res.id,
            name: label,
            parameterList: [],
            spaceIdOrProjectId: localStorage.eleProjectId,
            type: 1,
            creationTimeStr: this.getFullTime(),
            standList: [],
            parentId: 0
          })

          this.$message({
            type: 'success',
            message: '添加成功'
          })
        })

        this.floderName = ''
        this.isAddFloder = false
        this.focusFloderInput = false
      }
    },
    moveSelectFolder (val) {
      console.log(val)
      // this.$refs.moveClassForm.resetFields()
      let find = this.data.find(item => item.id == val)
      this.targetFolderList = find.standList
    },

    // 导入属性分类列表
    upload2 () {
      console.log(this.$refs['upload2'])
      this.$refs['upload2'].$children[0].$refs.input.click()
    },
    // 上传编码excel
    httpUpload2 (file, type) {
      var formData = new FormData()
      formData.append('formFile', file.file)
      formData.append('StandardId', this.curID)
      this.$axios.post(`/api/Standard/ImportParameter`, formData).then(res => {
        console.log(res)
			        this.getStandard()
        // this.handelNode(this.currAttributeNode)
        // 清空选中
        this.hoverID = 0
        this.curID = 0
        this.centerDate = []
        this.parameterList = []
        let mes = '上传成功，请再次选中【' + this.currAttributeNode.name + '】查看'
        this.$message({
          type: 'success',
          message: mes
        })
      })
    },
    beforeAvatarUpload2 (file) {
      const isEXCEL = (file.type === 'application/vnd.ms-excel' || file.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      if (!isEXCEL) {
        this.$message.error('上传文件只能是.xlsx、.xls格式!')
      }
      return isEXCEL
    },
    handleChange2 (file) {
      var file1 = {
        name: file.name,
        raw: file.raw
      }
    },

    // 导入属性列表
    upload1 () {
      this.$refs['upload'].$children[0].$refs.input.click()
    },
    // 上传编码excel
    upload (file, type) {
      var formData = new FormData()
      formData.append('formFile', file.file)
      formData.append('ParameterId', this.hoverID)
      this.$axios.post(`/api/Standard/ImportParameterInfo`, formData).then(res => {
        console.log(res)
        res.forEach(v => {
          var parInfo = {
            id: v.id,
            name: v.name,
            parameterId: this.hoverID,
            creatorName: localStorage.nickName,
            creationTimeStr: this.getFullTime()
          }
          this.parameterList.unshift(parInfo)
        })
        this.$message({
          type: 'success',
          message: '上传成功'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isEXCEL = (file.type === 'application/vnd.ms-excel' || file.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      if (!isEXCEL) {
        this.$message.error('上传文件只能是.xlsx、.xls格式!')
      }
      return isEXCEL
    },
    handleChange (file) {
      var file1 = {
        name: file.name,
        raw: file.raw
      }
    }
  }
}
</script>

<style lang="less" scoped>
	.code-container {

		padding: 20px;

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		.com {
			background: #f7f5f5;
			// padding-bottom: 55px;
			// border-radius: 10px;
			// height: calc(100vh - 170px);
			// height: 782px;
			height: 830px;
			overflow-y: auto;

			.template-header {
				display: flex;
				background-color: #ffffff;
				height: 70px;
				justify-content: space-between;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				align-items: center;
				padding: 0 20px;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #343434;
				margin-bottom: 10px;

				.header-right {
					display: flex;
					height: 40px;

					.hdBtnBox {
						display: flex;
						margin-left: 10px;

						.el-button--primary {
							background-color: #475065;
							border: none;
						}

						.el-button--primary:nth-child(3) {
							background-color: #0094ce;
						}
					}
				}
			}

			.pointer {
				cursor: pointer;
			}

			.template-main {
				display: flex;
				overflow-y: auto;
				// margin-top: 10px;
				// margin-left: 10px;

				// .tree-container-height-space {
				// 	height: calc(100vh - 295px);
				// }

				// .tree-container-height-project {
				// 	height: calc(100vh - 240px);
				// }

				.tree-container {
					width: 375px;
					height: 830px;
					background-color: #ffffff;
					box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
					overflow: hidden;

					.copySpan {
						float: right;
						right: 10px;
						cursor: pointer;
					}

					.title {
						text-align: left;
						background: #475065;
						height: 45px;
						line-height: 45px;
						padding: 0 10px;
						font-size: 16px;
						font-family: PingFangSC-Semibold, PingFang SC;
						color: #ffffff;

						.el-tree-node__expand-icon {
							display: none !important;
						}
					}

					.title2 {
						padding-left: 30px;
						border-top: 6px solid #475065;
						background: #f6f6f6;
						color: #000000;
						height: 40px;
						line-height: 40px;
					}

					.treeAdd {
						height: 45px;
						overflow-y: auto;

						.tree-add,
						.tree-one {
							cursor: pointer;
							height: 40px;
							line-height: 40px;
							text-align: left;
							padding-left: 40px;
						}

						.tree-add {
							height: 43px;
							line-height: 43px;
							padding: 0 10px;
							cursor: pointer;
							font-size: 18px;
							color: #868686;
							background-color: #f6f6f6;

							span {
								font-size: 14px;
								margin-right: 130px;
							}
						}
					}

					.tree-height-space {
						height: 800px;
						margin-bottom: 5px;
					}

					.tree-height-space2 {
						height: 530px;
						margin-bottom: 5px;
					}

					.tree-height-project {
						height: 637px;
					}

					.tree {
						overflow-y: auto;

						.tree-standard2 {
							.span1 {
								width: 250px !important;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
							.btn-container{
								display: none;
								justify-content: space-between;
								width: 58px;
								align-items: center;
								color: #0094ce;
							}
						}
						.tree-standard2:hover .btn-container{
							display: flex;
						}
						.tree-standard {
							.span1 {
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								text-align: left;
								width: 260px;
								display: -moz-inline-box;
								display: inline-block;
							}
							.params-list-btn{
								display: none;
								color: #0094ce;
								width: 100%;
								text-align: right;
								padding-right: 20px;
								i:nth-child(2){
									margin-left: 10px;
								}
							}
							.spanw {
								width: 150px;
							}

						}

						.tree-add,
						.tree-one {
							font-size: 14px;
							display: flex;
							align-items: center;
							cursor: pointer;
							height: 38px;
							line-height: 38px;
							text-align: left;
							padding-left: 40px;
							.span-icon-box {
								display: none;
								width: 90px;
								color: #0094ce;

								// background-color: #000000;
								i {
									margin: 0 5px;
								}
							}

							.shareStan {
								float: right;
								margin-right: 10px;
								color: #0094ce;
								display: none;
								font-size: 14px;

								.span2 {
									color: #f56c6c;
									margin-left: 8px;
								}

								.span3 {
									margin-left: 8px;
								}
							}

							.edit,
							.delete {
								display: none;
								color: #0094ce;
							}

							.redColor {
								// color: #f56c6c;
								color: #0094ce;
							}

						}
						.tree-one:hover .params-list-btn,
						.tree-one:hover .span-icon-box,
						.tree-one:hover .shareStan,
						.tree-one:hover .edit,
						.tree-one:hover .delete {
							display: inline;
						}

						.right {
							float: right;
							margin-right: 10px;
							margin-top: 12px;
						}

						.edit,
						.delete {
							display: none;
							color: #0094ce;
						}

						.edit {
							margin-right: 10px;
						}

						.tree-one-child:hover .edit,
						.tree-one-child:hover .delete {
							display: inline;
						}

						// .tree-one {
						// 	background: url("../../assets/file.png") no-repeat 10px 8px;
						// }

						.tree-one-item {
							background: unset;
							padding-left: 30px;
						}

						.tree-one-child {
							margin-left: 20px;

							span {
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								text-align: left;
								width: 160px;
								display: -moz-inline-box;
								display: inline-block;
							}

							.el-input {
								width: 92%;
							}

							.el-input__inner {
								height: 32px;
							}
						}

						.tree-one-father .tree-one {
							padding-left: 60px;
							background: url("../../assets/menu.png") no-repeat 30px 8px;
						}

						.tree-hover {
							background-color: #d8d8d8;
						}

						.tree-add {
							height: 43px;
							line-height: 43px;
							padding: 0 30px;
							cursor: pointer;
							font-size: 18px;
							color: #868686;
							background-color: #f6f6f6;

							span {
								font-size: 14px;
							}
						}
					}

					/* .el-tree-node__content {
		        height: 35px;
		      }
		      .el-tree-node__label {
		        background: url("../../assets/file.png") no-repeat;
		        padding-left: 30px;
		      }
		      .el-tree-node__children .el-tree-node__label {
		        background-image: url("../../assets/menu.png");
		      } */
				}

				.list-container {
					/* display: flex;
		      flex-wrap: wrap; */
					width: calc(100% - 300px);
					height: calc(100vh - 220px);
					overflow-y: auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-around;
					align-content: flex-start;

					.edit,
					.delete {
						position: absolute;
						top: 23px;
						right: 33px;
						display: none;
						color: #0094ce;
					}

					.edit {
						right: 66px;
					}

					.list-one:hover .edit,
					.list-one:hover .delete {
						display: inline;
					}

					.list-add-one {
						display: flex;
						justify-content: center;
						align-items: center;

					}

					.list-add-one .i1 {
						font-size: 51px;
						color: #d8d8d8;

					}

					.zhanwei {
						width: 438px;
						height: 139px;
					}

					.list-add-one,
					.list-one {
						position: relative;
						// float: left;
						margin: 25px 5px 10px 5px;
						width: 438px;
						height: 139px;
						padding: 23px 33px;
						background-color: #fff;
						box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
						border-radius: 8px;
						box-sizing: border-box;
						text-align: left;

						.title {
							padding-left: 25px;
							background: url("../../assets/one.png") no-repeat;
							font-size: 14px;
							height: 18px;
							line-height: 18px;
							font-weight: 500;
							color: #5f5f5f;
							margin-bottom: 28px;

							.el-input {
								width: 70%;
							}

							.el-input__inner {
								height: 24px;
							}

							///////
						}

						.msg-one {
							font-size: 12px;
							color: #5f5f5f;
							margin-bottom: 12px;
						}
					}
				}

				.list-container-noOne {
					position: relative;
					background: #fff url("../../assets/noOne.png") no-repeat center center;
					box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
					border-radius: 8px;
				}

				.list-container-noOne::before {
					position: absolute;
					content: "暂无内容";
					top: 63%;
					left: 47.5%;
					color: #868686;
				}
			}
		}
	}
</style>
